body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;
}
h1, h2, h3, h4, p {
  padding: 0;
  margin: 0;
}

p, div {
  font-family: var(--primary-font-family);
}

h1, h2, h3, h4 {
  font-family: var(--primary-font-family);
}
.header {
  font-size: var(--pr-header-font-size);
  font-weight: var(--bolder-font-weight);
  line-height: var(--header-line-height);
  
}
h1 {
  font-size: var(--pr-h1-font-size);
  font-weight: var(--medium-font-weight);
  line-height: var(--h1-line-height);
}
p {
  font-size: var(--pr-p-font-size);
  font-weight: var(--regular-font-weight);
  line-height: var(--p-line-height);
}

:root {
  /* ### colors */
  --primary-color: #ffc509;

  --light-background: #f5f3f4;

  /* Neutral Colors */
  --white: #ffffff;
  --black: #000000;

  /* ### font families */
  --primary-font-family: 'Montserrat';
  --secondary-font-family: 'Playfair Display';
  --tertiary-font-family: 'Barlow Condensed';

  /* ### font sizes */
  --pr-header-font-size: 4.3em;
  --pr-h1-font-size: 2em;
  --pr-p-font-size: 1.8em;
  --pr-p2-font-size: 1.3em;
  
  --sc-header-font-size: 5.5em;
  --sc-h1-font-size: 3em;
  --sc-p-font-size: 2em;
  
  /* ### line heights */
  /* Line Heights */
  --header-line-height: 1;
  --h1-line-height: 1;
  --p-line-height: 1.5;
  
  /* ### font weights */
  --light-font-weight: 300;
  --regular-font-weight: 400;
  --medium-font-weight: 500;
  --semi-bold-font-weight: 600;
  --bold-font-weight: 700;
  --bolder-font-weight: 800;
  
  /* ### max widths */
  --max-width-deskopt: 900px;
  --max-width-mobile: 350px;
}
.clamped {
  max-width: var(--max-width-deskopt);
  padding: 100px 24px;
  margin: 0 auto;
}
.semi-clamped {
  max-width: var(--max-width-deskopt);
  padding: 0 24px !important;
  margin: 0 auto;
  
}

@media (max-width: 800px) {
  :root {
    /* ### font sizes */
    --pr-header-font-size: 3.3em;
    --pr-h1-font-size: 1.2em;
    --pr-p-font-size: 1em;
    --pr-p2-font-size: .9em;
    
    --sc-header-font-size: 4.5em;
    --sc-h1-font-size: 2.1em;
    --sc-p-font-size: 1.5em;
  }
}
