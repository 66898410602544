div[hidden] {
    display: none;
}

/* ------------- */
nav {
    height: auto;
    box-sizing: border-box;
    background-color: var(--primary-color);
    
    display: grid;
    place-items: center;
}
.logo-container {
    height: 28px;
    padding: 10px 24px;
}
.logo-container img {
    height: 100%;
}
/* hero */
.hero-wrapper {
    background-color: var(--black);
}
.hero-text {
    color: var(--white);
}
.hero-text > p {
    font-family: var(--secondary-font-family);
    font-size: var(--sc-p-font-size);
    color: var(--primary-color);

    font-style: italic;
}
.hero-text > h1 {
    margin-top: 20px;
    text-transform: capitalize;
}
/* main */
.main-section-wrapper {
    background-color: var(--light-background);
    padding-bottom: 50px;
}
.main-content {
    transform: translateY(-50px);
}
.painting-container {
    height: 400px;
    width: 80%;

    margin: 0 auto;
}
.painting-container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.main-text,
.main-text p {
    margin-top: 40px;
}
.main-text h1 {
    text-align: center;
    font-family: var(--secondary-font-family);
    font-size: 2.6rem;
    font-weight: var(--medium-font-weight);
}

/* form */
.form-container {
    margin-top: 40px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* max-width: 200px; */
}
/* material ui input */
.form-container > .MuiTextField-root, .error-msg {
    width: 100%;
    max-width: 400px;
    margin-bottom: 24px;
}
.error-msg  {
    max-width: 380px;
    margin-bottom: 20px;
}
.MuiTextField-root .MuiOutlinedInput-root {
    background-color: var(--white) !important;
}
.MuiTextField-root .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: rgba(0, 0, 0, 1);
}

.MuiFormHelperText-contained {
    font-weight: 800 !important;
    font-size: 0.9rem !important;
}

.btn-submit {
    margin-top: 40px;

    border: none;
    outline: none;

    cursor: pointer;
    text-transform: uppercase;

    font-family: var(--primary-font-family);
    font-weight: var(--bolder-font-weight);
    font-size: var(--pr-p-font-size);
    letter-spacing: 0.5px;

    padding: 20px 50px;
    border-radius: 12px;

    background-color: var(--primary-color);

    transition: all 100ms ease;
}

.btn-dialog-container {
    margin: 0 auto;
}

.btn-dialog {
    margin-top: 40px;

    border: none;
    outline: none;

    cursor: pointer;
    text-transform: uppercase;

    font-family: var(--primary-font-family);
    font-weight: var(--bolder-font-weight);
    font-size: var(--pr-p-font-size);
    letter-spacing: 0.5px;

    padding: 20px 50px;
    border-radius: 12px;
    align-self: center;
    margin: 0 auto 16px;

    background-color: var(--primary-color);

    transition: all 100ms ease;
}
.MuiTextField-root .MuiOutlinedInput-notchedOutline {
    border-width: 3px;
}
.btn-submit:hover {
    filter: brightness(.9);
}
.btn-submit:active {
    transform: scale(.99);
}

/* footer */
footer {
    padding: 50px;
    background-color: var(--black);
}

p.error {
    color: #BD2323;
    border: 4px solid rgb(189, 35, 35);
    border-radius: 4px;
    padding: 12px 0;
    font-size: var(--pr-p2-font-size);
    width: 100%;
    max-width: 400px;
    background-color: rgb(189, 35, 35, 0.1);
    margin-bottom: 12px;
}

p.error::before {
    display: inline;
    content: "⚠ ";
}

/* dialog */
#alert-dialog-title > h2 {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;

    font-family: var(--primary-font-family);
    font-size: var(--pr-h1-font-size);
}
#alert-dialog-title svg {
    font-size: 3rem;
    color: #4BB543;
    margin-right: 4px;
}
#alert-dialog-title svg.error {
    color: #BD2323;
}
#alert-dialog-description {
    font-family: var(--primary-font-family);
    font-size: var(--pr-p2-font-size);
    color: var(--black);
}
.close-container {
    display: flex;
    align-items: flex-end;
    padding: 16px 24px 8px;
}
.close-container svg {
    padding: 5px;
    border-radius: 50%;
    margin-left: auto;
    font-size: 2.5rem;
    cursor: pointer;
    transition: all 200ms ease;
}
.close-container svg:hover {
    background-color: rgba(0, 0, 0, .1);
    padding: 5px;
}

/* error message */
.error-msg {
    background-color: #FFCDD2;
    border: 1px solid #ED5350;
    /* color: var(--white); */
    padding: 10px;
    border-radius: 4px;

}

[nowrap="true"] {
    white-space: nowrap;
}

@media (max-width: 1080px) {
    .main-text h1 {
        font-size: 2.2rem;
    }
}

@media (max-width: 800px) {
    .painting-container {
        height: 250px;
        width: 100%;

        margin: 0 auto;
    }

    [nowrap="true"] {
        white-space: initial;
    }
}